const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login"),
    meta: { guestOnly: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/auth/Signup"),
    meta: { guestOnly: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/auth/email/verify",
    name: "AuthEmailVerify",
    component: () => import("../views/auth/Verify"),
    meta: { authOnly: true },
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: () => import("../views/auth/ResetPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/",
    name: "FileUploads",
    component: () => import("../views/Files"),
    meta: { authOnly: true },
  },
  {
    path: "/supports",
    name: "Supports",
    component: () => import("../views/Supports"),
    meta: { authOnly: true },
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("../views/MyAccount"),
    meta: { authOnly: true },
  },
];

export default routes;
