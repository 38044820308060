<template>
  <div>
    <v-app-bar elevation="0" v-if="authenticated">
      <v-btn plain class="mr-2 text-transform-capitalize" to="/">
        Uploads
      </v-btn>
      <v-btn plain class="mr-2 text-transform-capitalize" to="/supports">
        Supports
      </v-btn>
      <v-spacer></v-spacer>
      <!--      <v-btn rounded elevation="0" color="indigo lighten-1" dark to="/login">
        Login
      </v-btn>-->
      <v-menu rounded="lg" offset-y transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="indigo lighten-1"
            class="white--text ma-5"
            v-bind="attrs"
            v-on="on"
            rounded
          >
            {{ user.name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in userMenu"
            :key="item.title"
            link
            @click="userMenuHandler(item.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-btn
              elevation="0"
              :loading="isLogoutLoading"
              @click="logoutHandler"
            >
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Auth from "@/services/Auth";
export default {
  name: "HeaderComponent",
  data() {
    return {
      drawer: false,
      isLogoutLoading: false,
      userMenu: [{ title: "My Account", route: "/my-account" }],
      mini: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async logoutHandler() {
      this.isLogoutLoading = true;
      await Auth.logout()
        .then(({ data: { message } }) => {
          this.setSnackbar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackbar({ text: data.message, type: "error" });
        })
        .finally(() => {
          this.logout();
          this.isLogoutLoading = false;
        });
    },
    setSnackbar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", {
        type: type,
        text: text,
      });
    },
    userMenuHandler(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped></style>
